import React from "react"

import SEO from "../../../components/seo"
import Header from "../../../components/header"

import { ImagePanorama, Viewer, Infospot } from "panolens"
import { navigate } from "gatsby"
import LoadingBar from "../../../components/loading-bar"
import PanoContainer from "../../../components/pano-container"
import ToolBar from "../../../components/tool-bar"

// Custom Icons
import ArrowMark from "../../../../static/icons/arrow-mark-white.png"

export default class EmergencyExit extends React.Component {
  constructor() {
    super()
    this.state = {
      progress: 0,
    }
  }

  setPositionToTarget(target, viewer) {
    const { x, y, z } = target.normalize()
    viewer.camera.position.set(x, -y, -z)
  }

  componentDidMount() {
    // Creating the different Panorama objects
    const mainPanorama = new ImagePanorama(
      "/departments/the-emergency-exit/panorama.png"
    )

    // Progress Events
    mainPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    // Create the InfoSpot to Go to the next department
    const doorInfoSpot = new Infospot(350, ArrowMark, false)
    doorInfoSpot.position.set(3599.79, -372.0, 3440.94)
    doorInfoSpot.addHoverText("Back to the reception")
    doorInfoSpot.addEventListener("click", () => {
      navigate("/departments/reception/")
    })

    // Add the InfoSpots to the Main Panorama
    mainPanorama.add(doorInfoSpot)

    const viewer = new Viewer({
      cameraFov: 90,
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    })

    viewer.add(mainPanorama)
  }

  loadingProgress(progressEvent) {
    var loaded = progressEvent.progress.loaded
    var total = progressEvent.progress.total
    var completedProgress = 365
    var percentage = Math.round((loaded / total) * completedProgress)

    this.setState({
      progress: percentage,
    })
  }

  render() {
    return (
      <>
        <SEO title="Emergency Exit" />
        <Header menuIsVisible={false} />
        <PanoContainer />
        <ToolBar />
        <LoadingBar progress={this.state.progress} />
      </>
    )
  }
}
